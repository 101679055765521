<script setup lang="ts">
definePageMeta({
  whiteBackground: true,
  isPublic: true,
  // The wide layout has been created specifically to make it possible to break content out of the container.
  // And it turned out to be difficult to add a flag on the layout itself, so we did it with a separate layout.
  // TODO: we should fix this... we should be able to change layouts for whole layers/apps.
  layout: 'wide',
})

const route = useRoute()
const slug = route.params.slug as string
</script>

<template>
  <ContentPage :slug="slug" />
</template>
